var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('jor_18bar'),_c('v-container',{attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"ข้อมูลสถานศึกษารายงาน สาขาวิชาสำหรับตำแหน่งว่าง"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-center font-weight-bold"},[_c('v-chip',{staticClass:"mr-1",attrs:{"color":"success"}},[_c('h3',[_c('v-icon',[_vm._v("mdi-check-circle")]),_vm._v(" "+_vm._s(_vm.collegeReport.countcf)+" ")],1)]),_c('v-chip',{staticClass:"font-weight-bold mr-1",attrs:{"color":"error"}},[_c('h3',[_c('v-icon',[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.collegeReport.countcc))],1)]),_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"warning"}},[_c('h3',[_c('v-icon',[_vm._v("mdi-progress-tag")]),_vm._v(" "+_vm._s(_vm.collegeReport.countpc)+" ")],1)])],1)])],1),_c('v-data-table',{staticClass:"mt-1",attrs:{"color":"success","headers":_vm.showHeaders,"items":_vm.college_manpower_reports,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',[_c('v-text-field',{staticClass:"mb-2",attrs:{"append-icon":"mdi-magnify","label":"ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง","single-line":"","hide-details":"","dense":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',[_c('v-select',{attrs:{"items":_vm.headers,"label":"เลือกคอลัมน์ที่ต้องการแสดง","multiple":"","outlined":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index < 6)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.text))])]):_vm._e(),(index === 6)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.selectedHeaders.length - 6)+" คอลัมน์)")]):_vm._e()]}}]),model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1)]},proxy:true},{key:"item.college_manpower_reportfile",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.college_manpower_reportfile != '-')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.viewpdf(item.college_manpower_reportfile)}}},[_c('v-icon',{attrs:{"size":"40","color":"info"}},[_vm._v("mdi-file-pdf-box")])],1):_vm._e()],1)]}},{key:"item.college_manpower_reportfilestatus",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.college_manpower_reportfilestatus === 'cc')?_c('v-btn',{attrs:{"outlined":"","rounded":"","color":"red"},on:{"click":function($event){return _vm.cancelAlert(item.college_manpower_reportref)}}},[_c('v-icon',{attrs:{"size":"30","color":"red"}},[_vm._v("mdi-alert")]),_c('v-icon',{attrs:{"size":"30","color":"red"}},[_vm._v("mdi-closed-caption")])],1):(item.college_manpower_reportfilestatus === 'cf')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"size":"30","color":"warning"}},[_vm._v("mdi-progress-tag")])],1)]}},{key:"item.college_manpower_reporttimestamp",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("moment")(item.college_manpower_reporttimestamp,"add", "543 year"),"D MMMM YYYY"))+" ")])]}},{key:"item.del_action",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-btn',{attrs:{"color":"error","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.delReport(item.college_manpower_reportref)}}},[_c('v-icon',{attrs:{"size":"30"}},[_vm._v("mdi-delete-circle")])],1)],1)]}}])},[_c('v-alert',{attrs:{"slot":"no-results","value":true,"color":"error","icon":"mdi-alert"},slot:"no-results"},[_vm._v("ไม่พบผลลัพธ์ \""+_vm._s(_vm.search)+"\" ที่คุณกำลังค้นหา.")])],1)],1),_c('v-layout',{attrs:{"row":"","justify-center":""}},[_c('v-dialog',{attrs:{"max-width":"80%"},model:{value:(_vm.viewDialog),callback:function ($$v) {_vm.viewDialog=$$v},expression:"viewDialog"}},[_c('v-card',{attrs:{"elevation":"2"}},[_c('embed',{attrs:{"src":'/HRvecCollegeIdPos/' + _vm.viewfile,"width":"100%","height":"700px"}})])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }