<template>
  <div>
    <jor_18bar />

    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลสถานศึกษารายงาน สาขาวิชาสำหรับตำแหน่งว่าง"
        class="px-5 py-3"
      >
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <div class="text-center font-weight-bold">
              <v-chip color="success" class="mr-1">
                <h3>
                  <v-icon>mdi-check-circle</v-icon> {{ collegeReport.countcf }}
                </h3>
              </v-chip>
              <v-chip color="error" class="font-weight-bold mr-1">
                <h3><v-icon>mdi-alert</v-icon> {{ collegeReport.countcc }}</h3>
              </v-chip>

              <v-chip color="warning" class="font-weight-bold">
                <h3>
                  <v-icon>mdi-progress-tag</v-icon> {{ collegeReport.countpc }}
                </h3>
              </v-chip>
            </div>
          </v-col>
        </v-row>
        <v-data-table
          color="success"
          :headers="showHeaders"
          :items="college_manpower_reports"
          :search="search"
          class="mt-1"
        >
          <template v-slot:top>
            <div>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </div>
            <div>
              <v-select
                v-model="selectedHeaders"
                :items="headers"
                label="เลือกคอลัมน์ที่ต้องการแสดง"
                multiple
                outlined
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 6">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 6" class="grey--text caption"
                    >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
                  >
                </template>
              </v-select>
            </div>
          </template>

          <template v-slot:item.college_manpower_reportfile="{ item }">
            <div>
              <v-btn
                v-if="item.college_manpower_reportfile != '-'"
                icon
                @click="viewpdf(item.college_manpower_reportfile)"
              >
                <v-icon size="40" color="info">mdi-file-pdf-box</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.college_manpower_reportfilestatus="{ item }">
            <div>
              <v-btn
                v-if="item.college_manpower_reportfilestatus === 'cc'"
                outlined
                rounded
                color="red"
                @click="cancelAlert(item.college_manpower_reportref)"
              >
                <v-icon size="30" color="red">mdi-alert</v-icon>
                <v-icon size="30" color="red">mdi-closed-caption</v-icon>
              </v-btn>

              <v-icon
                v-else-if="item.college_manpower_reportfilestatus === 'cf'"
                color="success"
                >mdi-check-circle</v-icon
              >

              <v-icon size="30" v-else color="warning">mdi-progress-tag</v-icon>
            </div>
          </template>

          <template v-slot:item.college_manpower_reporttimestamp="{ item }">
            <div>
              {{
                item.college_manpower_reporttimestamp
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </div>
          </template>

          <template v-slot:item.del_action="{ item }">
            <div>
              <v-btn
                @click="delReport(item.college_manpower_reportref)"
                color="error"
                fab
                small
                dark
                ><v-icon size="30">mdi-delete-circle</v-icon></v-btn
              >
            </div>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model viewDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="viewDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecCollegeIdPos/' + viewfile"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import jor_18bar from "../../components/admin/jor_18bar";

export default {
  components: { jor_18bar },
  data() {
    return {
      loading: true,
      ApiKey: "HRvec2021",
      search: "",
      headers: [],
      selectedHeaders: [],
      addcollege_manpower_reportdialog: false,
      headersMap: [
        { text: "ID_Ref", align: "left", value: "college_manpower_reportref" },
        {
          text: "สถานศึกษา",
          align: "left",
          value: "college_manpower_reportcollege"
        },
        { text: "สถานศึกษา", align: "left", value: "user_firstname" },
        { text: "จังหวัด", align: "left", value: "province_name" },
        { text: "ภาค", align: "center", value: "region_name" },
        {
          text: "จำนวน",
          align: "center",
          value: "college_manpower_reportcountpos"
        },
        { text: "ไฟล์", align: "center", value: "college_manpower_reportfile" },
        {
          text: "สถานะ",
          align: "center",
          value: "college_manpower_reportfilestatus"
        },
        {
          text: "วันที่",
          align: "center",
          value: "college_manpower_reporttimestamp"
        },
        {
          text: "ยกเลิกทั้งหมด",
          align: "center",
          value: "del_action"
        }
      ],

      editedIndex: -1,
      editedItem: {
        id_no: 0,
        j18_reason: ""
      },
      editedItemUpdate: {},
      defaultItem: {
        id_no: 0,
        j18_reason: ""
      },
      deletedItemmat: {},
      deletedItemmcm: {},
      college_manpower_reports: [],
      collegeReport: [],
      college_manpower_reportCC: [],
      college_manpower_CC: {},
      viewfile: [],
      viewDialog: false
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },

  async mounted() {
    await this.periodQuery();
    await this.college_manpower_reportQueryAll();
  },

  methods: {
    async viewpdf(college_manpower_reportfile) {
      this.viewfile = college_manpower_reportfile;
      this.viewDialog = true;
    },
    async delReport(college_manpower_reportref) {
      let result = await this.$http.post("college_manpower_report.php", {
        ApiKey: this.ApiKey,
        college_manpower_reportref: college_manpower_reportref
      });
      this.college_manpower_reportCC = result.data;

      Swal.fire({
        title: "ลบรายการ",
        text: "ลบเพื่อดำเนินการสร้างใหม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.college_manpower_reportCC.ApiKey = this.ApiKey;

          let result_delete = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.college_manpower_reportCC.college_manpower_reportfile
          });

          let resultup = await this.$http.post(
            "college_manpower_report.delete.php",
            this.college_manpower_reportCC
          );

          this.college_manpower_CC.ApiKey = this.ApiKey;
          this.college_manpower_CC.college_manpower_ref = college_manpower_reportref;

          let resultuprp = await this.$http.post(
            "college_manpower.deleteidref.php",
            this.college_manpower_CC
          );

          if (resultup.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.college_manpower_reportQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async cancelAlert(college_manpower_reportref) {
      let result = await this.$http.post("college_manpower_report.php", {
        ApiKey: this.ApiKey,
        college_manpower_reportref: college_manpower_reportref
      });
      this.college_manpower_reportCC = result.data;

      Swal.fire({
        title: "ขอยกเลิกการแนบไฟล์",
        text: "เนื่องจากแนบไฟล์ผิดพลาด",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยืนยัน",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.college_manpower_reportCC.ApiKey = this.ApiKey;

          let result_delete = await this.$http.post("deletefile.php", {
            ApiKey: this.ApiKey,
            filename:
              "../HRvecfiles/" +
              this.college_manpower_reportCC.college_manpower_reportfile
          });

          this.college_manpower_reportCC.college_manpower_reportfile = "-";

          this.college_manpower_reportCC.college_manpower_reportfilestatus =
            "-";

          let resultup = await this.$http.post(
            "college_manpower_report.update.php",
            this.college_manpower_reportCC
          );

          if (resultup.data.status == true) {
            Swal.fire({
              title: "ดำเนินการข้อมูลเรียบร้อย",
              icon: "success",
              showConfirmButton: false,
              timer: 1500
            });
            await this.college_manpower_reportQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
      });
    },
    async periodQuery() {
      let result_period_college;
      let result_period_filecollege;
      result_period_college = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_id: "701"
      });
      this.period_colleges = result_period_college.data;
    },

    close() {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    async editItem(item) {
      this.editedIndex = this.college_manpower_reports.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    async save() {
      this.editedItem.ApiKey = this.ApiKey;
      Object.assign(
        this.college_manpower_reports[this.editedIndex],
        this.editedItem
      );
      let resultmat = await this.$http.post(
        "college_manpower_report.updateIdno.php",
        this.editedItem
      );
      if (resultmat.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "บันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "ทำรายการซ้ำ",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.close();
    },

    async college_manpower_reportQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("college_manpower_report.php", {
          ApiKey: this.ApiKey,
          college_manpower_reporttime: this.period_colleges
            .period_college_times,
          college_manpower_reportyear: this.period_colleges.period_college_year
        })
        .finally(() => (this.loading = false));
      this.college_manpower_reports = result.data;
      let data = result.data;
      let countcc = 0;
      let countcf = 0;
      let countpc = 0;
      data.forEach(value => {
        if (value.college_manpower_reportfilestatus == "cc") {
          countcc += 1;
        } else if (value.college_manpower_reportfilestatus == "cf") {
          countcf += 1;
        } else {
          countpc += 1;
        }
      });
      this.collegeReport.countcc = countcc;
      this.collegeReport.countcf = countcf;
      this.collegeReport.countpc = countpc;
    },
    async college_manpower_reportAdd() {
      this.addcollege_manpower_report = {};
      this.addcollege_manpower_reportdialog = true;
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
  },
  computed: {
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
